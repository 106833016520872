import { useEffect, useRef, useState } from "react";
import { Container } from "./styles";

import { Loader } from "../../components/Loader";
import { Button } from "../../../src/components/System/Button"

interface ValueManagerProps {
    changeValue?: (value: number) => void;
    initialValue?: number;
    processing?: boolean;
    money?: boolean;
    maxValue?: number;
    error?: boolean;
}

export function ValueManager(props: ValueManagerProps) {
    const { changeValue, initialValue = 0, processing = false, maxValue = 999999, money = false, error=false } = props;
    
    const [value, setValue] = useState<string>(formatInput(initialValue));
    const [editing, setEditing] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    function formatInput(value: number): string {
        if (money) {
            return Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        }
        return Number(value).toLocaleString('pt-br')
    }

    function cleanUpNumber(inputValue: string): number {
        let clearedNumber = inputValue.replace(/[^0-9]/g, '');
        if (clearedNumber.length === 0) return 0
        return parseInt(clearedNumber)
    }

    function handleType(e: string) {
        const factor = money ? 100 : 1
        const clearedNumber = cleanUpNumber(e) / factor
        if (clearedNumber > maxValue) return
        setValue(formatInput(clearedNumber))
    }

    const handleFocus = (event: { target: { select: () => any; }; }) => {
        event.target.select()
    };


    function handleEdit() {
        setEditing(true)
    }

    useEffect(() => {
        if (!editing) return
        if (inputRef.current) inputRef.current.focus();
    }, [editing])

    function handleCancel() {
        setValue(formatInput(initialValue))
        setEditing(false)
    }

    useEffect(() => {
        if (!processing && saving) setSaving(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processing])

    function handleSave() {
        setSaving(true)
        setEditing(false)
        const factor = money ? 100 : 1
        const newValue = cleanUpNumber(value) / factor
        if (changeValue) changeValue(newValue)
    }

    return (
        <Container>

            <div className={`input-contador ${editing && "active"} `}>
                <input className={`${!changeValue && "block"}`}
                    type="text"
                    value={value}
                    onChange={(e) => handleType(e.target.value)}
                    onFocus={handleFocus}
                    disabled={!editing}
                    ref={inputRef}
                />
                {processing &&
                    <span className="processingContainer">
                        <Loader width={40} height={40} />
                    </span>
                }
            </div>

            {!editing && !processing && !error &&
                <Button disabled={!changeValue || processing} onClick={handleEdit} type="submit">
                    Editar
                </Button>
            }

            {editing && !saving &&
                <>
                    <Button onClick={handleSave} type="submit" disabled={value === formatInput(initialValue)}>
                        Atualizar
                    </Button>
                    <span>
                        <Button onClick={handleCancel} type="submit">
                            Cancelar
                        </Button>
                    </span>
                </>
            }
            {processing && !error && <span className="warning"> {saving ? "Atualizando..." : "Carregando..."} </span>}
            {error && <span className="warning error"> Erro ao <br/> atualizar </span>}
        </Container>
    );
}
