import { Text } from "alisson-application";
import { UseCartoes } from "../../Hook/user/UseCartaoes";

import ImgBoleto from "../../assets/img/boleto-verde.svg";
import ImgCalendario from "../../assets/img/calendario.svg";
import ImgVirtualCartao from "../../assets/img/crashGreen.svg";
import { Container } from "./styles";
import { Button } from "../Mobile/ButtonSlim";
import { UseModal } from "../../Hook/Usemodal";
import { tempoFimPlataforma, tempoInicioPlataforma } from "../../util/helper";

export function LimiteBoleto() {
    const { cartao } = UseCartoes();
    const { handleClose } = UseModal();
    const hasTypePhysic = cartao?.tipo === "cartao_fisico";

    return (
        <Container>
            <div className="img-close">
                <img
                    src={ImgVirtualCartao}
                    alt=""
                />
            </div>

            <div className="container-title">
                <Text as="h2">Ops! Limite Atingido</Text>
                <Text as="p">
                    Cartões {hasTypePhysic ? "Físicos" : "Virtuais"} possuem:
                </Text>
            </div>

            <div className="container-detalhes">
                <div className="container-img">
                    <img src={ImgBoleto} alt="" />
                </div>
                <Text as="p">Limite por boleto:</Text>
                <Text as="span">
                    <strong>{hasTypePhysic ? "R$ 1.500,00" : "R$ 1.500,00"}</strong>
                </Text>
            </div>

            <div className="container-detalhes">
                <div className="container-img">
                    <img src={ImgCalendario} alt="" />
                </div>
                <Text as="p">Limite mensal:</Text>
                <Text as="span">
                    <strong>{hasTypePhysic ? "R$ 10.00,00" : "R$ 1.500,00"}</strong>
                </Text>
                <Text as="small">
                    (válido em dias úteis, das {tempoInicioPlataforma}h às{" "}
                    {tempoFimPlataforma}h)
                </Text>
            </div>
            <div className="buttonContainer">
                <Button onClick={() => handleClose("limiteBoleto")}>
                    Ok, Entendi
                </Button>
            </div>
        </Container>
    );
}
