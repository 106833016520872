import { useState } from "react";
import { Box, Flex } from "alisson-application";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { useHistory } from "react-router-dom";

import { Button } from "../../Button";
import { useTermoECondicoesDeUso } from "../../../../Hook/system/TermoECondicoesDeUso";
import { Myform, initalValues, validadeForm } from "./config";
import { StylesForm } from "./styles";
import { ButtonUpload } from "../../ButtonUpload";
import Select, { options } from "../../../NovoSelect";
import { ButtonUploadDefault } from "../../ButtonUpload/button";
import { IOSSwitch } from "../../Switch";

export type keyOf<T> = keyof T;

export function FormTermoPolitica() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<File>({} as File);
    const { store } = useTermoECondicoesDeUso();

    const tipo: options[] = [
        {
            label: "Termos e Condições de Uso",
            value: "termo",
        },
        {
            label: "Aviso de Privacidade",
            value: "politica",
        },
    ];

    return (
        <StylesForm>
            <Formik
                isInitialValid={false}
                initialValues={initalValues}
                validationSchema={validadeForm}
                onSubmit={async (values, actions) => {
                    setLoading(true);

                    values = {
                        ...values,
                        send_notification_users: values.send_notification_users
                            ? 1
                            : 0,
                    };

                    const formData = new FormData();

                    const keys = Object.keys(values) as keyOf<Myform>[];

                    keys.forEach((key) => {
                        if (key !== "file") {
                            formData.append(key, values[key] as string);
                        }
                    });

                    if (file.size) {
                        formData.append("file", file ? file : "");
                    }

                    try {
                        const response = await store(formData);

                        if (response.status === 200) {
                            history.push("/sistema/termo-e-condicoes-de-uso");
                        }
                    } catch (error) {
                        console.log(error);
                    }

                    setLoading(false);
                }}
            >
                {(props: FormikProps<Myform>) => {
                    const { setFieldValue, values } = props;

                    return (
                        <Form>
                            <Box className="container-input-system">
                                <Field
                                    type="text"
                                    placeholder="TITULO"
                                    name="titulo"
                                />
                            </Box>
                            <ErrorMessage component="span" name="titulo" />

                            <Select
                                label="TIPO"
                                options={tipo}
                                onChange={(values) => {
                                    setFieldValue("tipo", values.value);
                                }}
                            />

                            <Box className="container-input-system">
                                <Field
                                    component="textarea"
                                    placeholder="DESCRIÇÃO"
                                    name="descricao"
                                />
                            </Box>
                            <ErrorMessage component="span" name="descricao" />

                            <ButtonUpload
                                config={{
                                    accept: ".pdf",
                                    id: "img-whatsapp",
                                }}
                                onChange={(values) => {
                                    if (values) {
                                        setFile(values[0]);
                                        setFieldValue("file", "ok");
                                    }
                                }}
                            >
                                <ButtonUploadDefault name={file?.name} />
                            </ButtonUpload>
                            <ErrorMessage component="span" name="file" />

                            <Flex m="38px 0px" className="toggle-item">
                                <IOSSwitch
                                    checked={
                                        values.send_notification_users
                                            ? true
                                            : false
                                    }
                                    onChange={(e) =>
                                        setFieldValue(
                                            "send_notification_users",
                                            e.target.checked ? 1 : 0
                                        )
                                    }
                                />
                                <p>
                                    Desejo disparar um comunicado com e-mail
                                    marketing sobre a atualização dos termos e
                                    condições de uso ou aviso de privacidade.
                                </p>
                            </Flex>

                            <Box mt="16px" ml="auto" width="220px">
                                <Button
                                    isLoading={loading}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                    type="submit"
                                    disabled={!props.isValid}
                                >
                                    ADICIONAR
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </StylesForm>
    );
}
