import { Text } from "alisson-application";
import { ValueManager } from "../../../../ValueManager";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import { useCampanha, Fraude } from "../../../../../Hook/system/useCampanha";

export function AntiFraude() {
    const [limiteCartaoNonamed, setLimiteCartaoNonamed] = useState<number | null>(null);
    const [errorCartaoNonamed, setErrorCartaoNonamed] = useState<boolean>(false);
    const { campanhaById, updateCampanha, loading, setCampanhaById} = useCampanha();

    async function handleNoNameCharge(value: number) {
        const newValue:Fraude = {limite_valor_ativar_cartao_nonamed: value};
        const response = await updateCampanha(newValue);

        if (response.status === 200) {  
           setCampanhaById({...campanhaById, limite_valor_ativar_cartao_nonamed: value})
        }else{
            setErrorCartaoNonamed(true); 
        }
    }
    
    useEffect(() => {
        if(Object.keys(campanhaById).length === 0) return;
        setLimiteCartaoNonamed(campanhaById.limite_valor_ativar_cartao_nonamed);
    }, [campanhaById])

    return (
        <Container>
            <div className="limit">
                <Text as="p">LIMITE DE CREDITOS POR DIA</Text>
                <ValueManager maxValue= {999999} />
            </div>
            <div className="limit">
                <Text as="p">LIMITE DE CREDITOS RESGASTES POR DIA</Text>
                <ValueManager initialValue={0} maxValue= {999999} />
            </div>
            <div className="limit">
                <Text as="p">CARGA CRÉDITO DE CARTÃO NONAME</Text>
                {limiteCartaoNonamed &&<ValueManager initialValue={limiteCartaoNonamed}  maxValue= {50000.00} money changeValue={handleNoNameCharge} processing={loading} error={errorCartaoNonamed}/>}
                {!limiteCartaoNonamed &&<ValueManager  money  processing={true }/>}
            </div>
        </Container>
    );
}

