import styled from "styled-components";

export const UploadStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: ${({ theme }) => theme.colors.backgroundAlt};
    padding-left: 92px;
    position: relative;
    &:after {
        content: "";
        display: block;
        width: 80px;
        height: 80px;
        background-color: ${({ theme }) => theme.colors.backgroundAlt};
        position: absolute;
        top: 0px;
        right: -80px;
    }
    .container-text {
        span {
            display: flex;
            align-items: center;
            button {
                margin-left: 9px;
            }
        }
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.primary};
            width: 278px;
            strong {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.secondary};
            }
        }
    }

    .container-input-file {
        input[type="file"] {
            display: none;
        }

        label {
            background: #ffffff;
            border-radius: 4px;
            padding-left: 18px;
            width: 656px;
            height: 40px;
            display: block;
            position: relative;

            .container-text {
                position: absolute;
                top: 55%;
                left: 18px;
                transform: translateY(-50%);
            }

            .container-img {
                background: #acafb1;
                border-radius: 4px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                pointer-events: none;
                transition: all 0.3s ease;
                &.active {
                    cursor: pointer;
                    pointer-events: all;
                    background-color: ${({ theme }) => theme.colors.secondary};
                    transition: all 0.3s ease;
                }
            }
        }
    }
`;

export const TableStyles = styled.div`
    padding-left: 93px;
    .container-loading {
        position: relative;
        min-height: calc(100vh - 250px);
    }
    .table {
        .tr {
            &.header {
                padding-bottom: 31px;
                margin-bottom: 21px;
            }
        }
    }
`;

export const ReeviewStyles = styled.div`
    position: relative;
    display: flex;
    background: #fafafa;
    padding-left: 92px;
    align-items: start;
    padding: 15px 0 15px 92px;

    @media (min-width: 1401px) {
            align-items: center;
    }

    &:after {
        content: "";
        display: block;
        width: 80px;
        height: 100%;
        background-color: #fafafa;
        position: absolute;
        top: 0px;
        right: -80px;
    }
    ul {
        display: grid;
        grid-gap: 5px 20px;
        width: 90%;
        max-width:2024px;
        

        @media (min-width: 1401px) {
            grid-template-columns: minmax(120px,300px) 115px 168px ;
            
        }

        @media (min-width: 1720px) {
            grid-template-columns: minmax(120px,300px) 115px 168px minmax(120px,300px) 30px 140px;
        }


        li {
            display: flex;
            align-items: center;
            p {
                font-style: normal;
                font-weight: 500;
                font-size: ${({ theme }) => theme.fonts.xs};
                line-height: 100%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.primary};
                align-items: left;

                @media (min-width: 1720px) {
                    align-items: center;            
                }
                &.processando {
                    color: ${({ theme }) => theme.colors.warning};
                }
            }

            &.title,
            &.name {
                p {
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    text-align: left;
                }
            }
        }
    }

    .acoes {
        margin-left: 20px;
        width: 284px;
        /* width: 190px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.not-permission {
            width: 75px;
        }
        .background {
            background-color: ${({ theme }) => theme.colors.white};
            padding: 5px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;

export const Container = styled.div`
    padding-right: 38px;
`;

export const BuscaStyles = styled.div`
    .busca {
        width: 500px;
        margin-left: auto;
        margin-top: 32px;
    }
`;
