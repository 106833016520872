import { Text } from "alisson-application";

import { Button } from "../ButtonSlim";
import { usePassos } from "../../../Hook/UsePassos";
import imgBemVindo from "../../../assets/img/bem-vindo.svg";
import { BemVindoStyles } from "./styles";

export function BemVindo() {
    const { nextPasso } = usePassos();
    return (
        <BemVindoStyles>
            <div className="container-img">
                <img src={imgBemVindo} alt="" />
            </div>

            <Text as="h1">
                É bom ter você <br />
                na YetzPay!
            </Text>

            <div className="texts">
                <Text as="p">
                    Aqui você vai poder gerenciar sua carteira e todos os
                    cartões vinculados a ela.
                </Text>

                <Text as="p">
                    É bem fácil e rápido, mas antes de começar, precisamos da
                    sua <strong>TOTAL ATENÇÃO</strong> para validar algumas
                    informações:
                </Text>
            </div>

            <Button onClick={() => nextPasso(2)}>Vamos Lá</Button>
        </BemVindoStyles>
    );
}
