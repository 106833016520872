import { RelatorioIdWall } from "../../../components/System/RelatorioIdWall";
import { RelatorioProviderIdWall } from "../../../Hook/system/useRelatorioIdWall";

export function PageRelatorioIdWall() {
    return (
        <RelatorioProviderIdWall>
            <RelatorioIdWall />
        </RelatorioProviderIdWall>
    );
}
