import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap:16px;

    .input-contador {
        position: relative;
        border: 1px solid #e1e2e3;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 10px 7px;

        &.active {
            border-color: ${({ theme }) => theme.colors.secondarydark};
        }

        input{
            max-width: 110px;
            font-size: 16px;
            font-weight: 500;
            border: none;
            margin: 0 10px;
            text-align: center;
            padding-top:3px;
            font-family: "Oxanium", Arial, Helvetica, sans-serif;
            backGround-color: ${({ theme }) => theme.colors.white};

            &[disabled] { 
                color: ${({ theme }) => theme.colors.black};
                opacity: 0.6
            }

            &.block {
                opacity: 0.3;
            }
        }
    }
    
    button{
        width: 120px;
        font-family: "Oxanium", Arial, Helvetica, sans-serif;
        font-weight: 600;
    }
    
    span{
        button{
            background-color: ${({ theme }) => theme.colors.failure};
        }
    }

    .processingContainer{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .warning{
        display: flex;
        align-items: center;
        justify-content:center;

        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 120%;
        text-align: center;

        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 0px 32px;
        width: 120px;
        height: 44px;
        color: ${({ theme }) => theme.colors.secondary};
        font-family: "Oxanium", Arial, Helvetica, sans-serif;
    }   

    .error{
        color: ${({ theme }) => theme.colors.failure};
    }

`;
