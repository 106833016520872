import styled from "styled-components";

export const MaintenanceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: ${(props) => props.theme.colors.secondary};
  width: 100%;
  min-height: 100vh;
  height: max-content;
  top: 0;
  right: 0;
  gap: 100px;

  h1, h3, p {
    color: #FFF;
    font-family: Oxanium;
    font-style: normal;
  }

  h1 {

    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    margin: 10px 0 35px;
  }

  p.text-aviso {
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;

    strong {
      font-weight: 700;
    }
  }

  h3 {
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
    margin: 30px 0 15px;
  }

  .content-form.manutencao {
    max-width: 320px;
  }

  img.logo {
    max-width: 197px;
  }

  .retorno {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px 12px 23px;
    margin-bottom: 35px;
    width: max-content;

    .container-text {
      margin-left: 21px;

      p {
        font-family: Oxanium;
        font-style: normal;
        font-weight: 700;
        font-size: 16.4368px;
        line-height: 20px;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.secondary};
        margin: 0px !important;
      }

      span {
        font-family: Oxanium;
        font-style: normal;
        font-weight: 400;
        font-size: 16.4368px;
        line-height: 20px;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primaryDark};
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 80px 0;
    gap: 10px;

    .container-img-manutencao img {
      width: 280px;
    }

    .icon {
      width: 50px;
      height: 50px;
    }

    .retorno .container-text p {
      font-size: 14px
    }

    .content-form.manutencao {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      h1 {
        font-size: 32px;
        text-align: center;
        margin: 10px 0 20px ;
      }
      
      h3 {
        font-size: 22px;
        text-align: center;
        margin: 0 0 30px;
      }
      
      p.text-aviso {
        font-size: 16px;
        text-align: center;
        max-width: 260px;
        
        strong.advise { 
          display: none;
        } 
      }
    }
  }

`