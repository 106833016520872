import { useState } from "react";
import { Text } from "alisson-application";
import { getLocalStore } from "../../../util/helper";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { IEmail, email, validadeEmail } from "./config";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";

import { Button } from "../ButtonSlim";

import imgValidar from "../../../assets/img/validar-email.svg";
import { ValidarEmailStyles } from "./styles";

export function ValidarEmail() {
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const { updateUser } = useUser();
    const { nextPasso } = usePassos();

    return (
        <ValidarEmailStyles>
            <Formik
                initialValues={email}
                validationSchema={validadeEmail}
                onSubmit={async (values, actions) => {
                    const { email } = values;
                    if (getLocalStore()?.email !== email) {
                        setLoading(true);
                        const response = await updateUser({ email });
                        if (response.status === 200) {
                            nextPasso(3);
                        }
                        setLoading(false);
                    } else {
                        nextPasso(3);
                    }
                }}
            >
                {(props: FormikProps<IEmail>) => {
                    return (
                        <Form>
                            <div className="container-img">
                                <img src={imgValidar} alt="" />
                            </div>

                            <div className="change-email">
                                <Text as="h3">Você cadastrou o e-mail</Text>

                                <div
                                    className={`container-input contato ${
                                        disabled ? "active" : ""
                                    }`}
                                >
                                    <Field
                                        placeholder="E-mail"
                                        name="email"
                                        type="email"
                                        disabled={disabled}
                                    />
                                </div>
                                <ErrorMessage name="email" component="span" />

                                <button
                                    className="alterar"
                                    type="button"
                                    onClick={() => setDisabled(!disabled)}
                                >
                                    ALTERAR EMAIL
                                </button>
                                <Text as="p">
                                    Precisamos validá-lo. Para isso enviamos um
                                    e-mail de confirmação. Caso não tenha
                                    recebido verifique a caixa de spam ou
                                    reenvie a mensagem acessando o seu perfil no
                                    menu.
                                </Text>

                                <Button
                                    type="submit"
                                    isLoading={loading}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                >
                                    Está tudo certo, Avançar
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </ValidarEmailStyles>
    );
}
