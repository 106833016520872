import { Box, Text } from "alisson-application";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { motion } from "framer-motion";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Mask from "react-input-mask";
// import pdfPoliticaDePrivacidade from "../../../assets/pdf/Politica_de_privacidade.pdf";
import pdfRegulamento from "../../../assets/pdf/regulamento.pdf";
import { UseModal } from "../../../Hook/Usemodal";
import { UseLogin, Values } from "../../../Hook/user/UseLogin";
import { formatCep } from "../../../util/helper";
import { Button } from "../Button";
import { CheckBox } from "../CheckBox";

import axios from "axios";
import imgLupa from "../../../assets/img/lupa-branca.svg";
import imgSetaBaixo from "../../../assets/img/seta-baixo.svg";
import { ObejctForm } from "../../../Hook/user/useFormLogin";
import { useIdWall } from "../../../Hook/user/useIdWall";
import { api } from "../../../services";
import {
    endereco,
    IEndereco,
    isValidBRZipRegex,
    validationEndereco,
} from "./config";
const regex = /[^\d]+/g;
interface FormRegisterProps {
    state: ObejctForm;
    onClick: () => void;
}

interface Cep {
    bairro: string;
    cep: string;
    complemento: string;
    ddd: string;
    gia: string;
    ibge: string;
    localidade: string;
    logradouro: string;
    siafi: string;
    uf: string;
    erro?: boolean;
}

interface ObjCep {
    cep: string;
    estado: string;
    cidade: string;
    nome: string;
    logradouro: string;
    bairro: string;
}

export function Endereco(props: FormRegisterProps) {
    const { state, onClick } = props;
    const { registro } = UseLogin();
    const { idWallUser } = useIdWall();
    const { setState, handleOpen, state: modalState } = UseModal();
    const [loading, setLoading] = useState(false);
    const [loadingCep, setLoadingCep] = useState(false);
    const [initalValues, setInitalValues] = useState<IEndereco>(endereco);
    const [disabledNumero, setDisabledNumero] = useState(false);
    const [requestCep, setRequestCep] = useState(false);
    const [politicamenteExposta, setPoliticamenteExposta] = useState(false);
    const [objCorreios, setObjCorreios] = useState<ObjCep>({} as ObjCep);
    const [cep, setCep] = useState("");
    // const img = window.screen.width <= 480 ? imgLupa : imgLupaVerde;

    const findCep = useCallback(
        async (value: string) => {
            try {
                setLoadingCep(true);
                const response = await axios.get<Cep>(
                    `https://viacep.com.br/ws/${value.replace(regex, "")}/json/`
                );

                if (response.data.erro) {
                    setLoadingCep(false);
                    setState({
                        ...modalState,
                        errorGeral: {
                            active: true,
                            message: "CEP não encontrado",
                            title: "CEP",
                        },
                    });
                    setInitalValues(endereco);
                    setRequestCep(false);
                    return;
                }

                const obj = {
                    cep: formatCep(value),
                    estado: response.data.uf,
                    cidade: response.data.localidade,
                    nome: response.data.logradouro,
                    logradouro: response.data.logradouro,
                    bairro: response.data.bairro || "",
                };
                setObjCorreios(obj);
                setRequestCep(true);
                setInitalValues({ ...initalValues, ...obj });
                setLoadingCep(false);
            } catch (error) {
                setLoadingCep(false);
                console.log(error);
            }
        },
        [initalValues, modalState, setState]
    );

    useEffect(() => {
        if (isValidBRZipRegex.test(cep)) {
            findCep(cep.replace(regex, ""));
            setCep("");
        }
    }, [cep, findCep]);

    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={initalValues}
            validationSchema={validationEndereco}
            onSubmit={async (values, actions) => {
                setLoading(true);

                values = {
                    ...values,
                    cep: values.cep.replace(regex, ""),
                };

                const response = await registro({
                    ...state,
                    ...values,
                } as unknown as Values);

                if (response.status === 200) {
                    if (!state.idwall_service_status) {
                        const dados = state as any;
                        await api.post("send-idwall-sdktoken", {
                            document: dados.document,
                            data_nascimento: dados.data_nascimento,
                            name: dados.name,
                            email: dados.email,
                            phone_number: dados.phone_number,
                        });
                    } else {
                        await api.post("send-idwall-sdktoken", {
                            ...idWallUser,
                        });
                    }

                    handleOpen("validandoDadosIdWall", {
                        document: state.document,
                    });
                }

                setLoading(false);
            }}
        >
            {(props: FormikProps<IEndereco>) => {
                const { values, setFieldValue } = props;
                const { cep } = values;

                return (
                    <Form>
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.7,
                            }}
                            className={`container-input cpf-cnpj endereco cep`}
                        >
                            <Mask
                                mask="99999-999"
                                placeholder="CEP"
                                name="cep"
                                type="tel"
                                onChange={(e) => {
                                    setFieldValue("cep", e.target.value);
                                    setCep(e.target.value);
                                }}
                                value={cep}
                            />

                            <Button
                                className="btn-lupa"
                                type="button"
                                isLoading={loadingCep}
                                spiner="BallTriangle"
                                disabled={!isValidBRZipRegex.test(values.cep)}
                                onClick={() => findCep(values.cep)}
                            >
                                <img src={imgLupa} alt="" />
                            </Button>
                        </motion.div>
                        <ErrorMessage name="cep" component="span" />
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.8,
                            }}
                            className={`container-input cpf-cnpj endereco  ${
                                values.estado ? "active" : ""
                            } ${values.estado ? "disabled" : ""}
                            ${!requestCep ? "disabled" : ""}`}
                        >
                            <Field
                                name="estado"
                                placeholder="Estado (Resultado do CEP)"
                                autoComplete="new-password"
                            />
                        </motion.div>
                        <ErrorMessage name="estado" component="span" />
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.9,
                            }}
                            className={`container-input cpf-cnpj endereco  ${
                                values.cidade ? "active" : ""
                            } ${
                                values.estado && values.cidade ? "disabled" : ""
                            } ${!requestCep ? "disabled" : ""}`}
                        >
                            <Field
                                name="cidade"
                                placeholder="Cidade (Resultado do CEP)"
                                autoComplete="new-password"
                            />
                        </motion.div>
                        <ErrorMessage name="cidade" component="span" />
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1,
                            }}
                            className={`container-input cpf-cnpj casa ${
                                values.logradouro ? "active" : ""
                            } ${
                                !requestCep
                                    ? "disabled"
                                    : objCorreios.logradouro
                                    ? "disabled"
                                    : ""
                            }`}
                        >
                            <Field
                                name="logradouro"
                                placeholder="Logradouro (Resultado do CEP)"
                                autoComplete="new-password"
                                value={values.logradouro}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    setFieldValue("logradouro", e.target.value);
                                    setFieldValue("nome", e.target.value);
                                }}
                            />
                        </motion.div>
                        <ErrorMessage name="logradouro" component="span" />
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.9,
                            }}
                            className={`container-input cpf-cnpj casa ${
                                values.bairro ? "active" : ""
                            } ${
                                !requestCep
                                    ? "disabled"
                                    : objCorreios.bairro
                                    ? "disabled"
                                    : ""
                            }`}
                        >
                            <Field
                                name="bairro"
                                placeholder="Bairro (Resultad do CEP?)"
                                autoComplete="new-password"
                            />
                        </motion.div>
                        <ErrorMessage name="bairro" component="span" />
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1.2,
                            }}
                            className={`container-input cpf-cnpj casa ${
                                disabledNumero ? "disabled active" : ""
                            } ${values.numero ? "active" : ""}`}
                        >
                            <input
                                name="numero"
                                type="tel"
                                onChange={(e) =>
                                    setFieldValue(
                                        "numero",
                                        e.target.value.replace(/[^\d]+/g, "")
                                    )
                                }
                                autoComplete="new-password"
                                maxLength={5}
                                value={values.numero}
                                placeholder="Número"
                            />
                        </motion.div>
                        <ErrorMessage name="numero" component="span" />
                        <Box pl="12px" mb="12px">
                            <li>
                                <CheckBox
                                    label="Sem número"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setFieldValue("numero", "1");
                                            setDisabledNumero(true);
                                        } else {
                                            setFieldValue("numero", "");
                                            setDisabledNumero(false);
                                        }
                                    }}
                                />
                            </li>
                        </Box>

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1.3,
                            }}
                            className={`container-input cpf-cnpj casa`}
                        >
                            <Field
                                name="complemento"
                                type="text"
                                placeholder="Complemento"
                            />
                        </motion.div>
                        <ErrorMessage name="complemento" component="span" />
                        <ul className="container-checks">
                            <li>
                                <CheckBox
                                    label="Aceito receber mensagens via SMS e <br /> Whatsapp"
                                    checked={true}
                                />
                            </li>
                            <li>
                                <CheckBox
                                    label="Aceito receber mensagens via E-mail"
                                    onChange={(e) =>
                                        setFieldValue(
                                            "aceita_email",
                                            e.target.checked
                                        )
                                    }
                                    checked={values.aceita_email}
                                />
                            </li>
                            <li className="li-acordo">
                                <CheckBox
                                    label={`Li, compreendi e concordo com as <br /> disposições contidas nos <a href="${pdfRegulamento}" target='_blank' rel='noopener noreferrer'><strong>Termos de <br /> Uso</strong>  desta Plataforma.</a> Caso não <br /> concorde, clique no botão cancelar.`}
                                    onChange={(e) => {
                                        setFieldValue(
                                            "aceita_termos",
                                            e.target.checked
                                        );
                                    }}
                                />
                            </li>
                            {!values.aceita_termos && (
                                <span>Campo obrigatório</span>
                            )}
                            <li className="li-acordo">
                                <CheckBox
                                    label={`Aceito o <strong><a href="https://yetz.com.br/aviso-de-privacidade/" target='_blank' rel='noopener noreferrer'>Aviso de Privacidade</a></strong>`}
                                    checked={values.aceita_politica_uso_de_dados}
                                    onChange={(e) => {
                                        setFieldValue(
                                            "aceita_politica_uso_de_dados",
                                            e.target.checked
                                        );
                                    }}
                                />
                            </li>
                            {/* {!values.aceita_politica_uso_de_dados && (
                                <span>Campo obrigatório</span>
                            )} */}

                            <li className="li-acordo">
                                <CheckBox
                                    label="Confirmo não ser uma Pessoa <br /> Politicamente  Exposta"
                                    onChange={(e) => {
                                        setFieldValue(
                                            "aceita_politicamente_exposta",
                                            e.target.checked
                                        );
                                    }}
                                />
                            </li>
                            {!values.aceita_politicamente_exposta && (
                                <span>Campo obrigatório</span>
                            )}

                            <div
                                className={`container-text-politicamente-exposta ${
                                    politicamenteExposta ? "active" : ""
                                }`}
                            >
                                <small>
                                    (que desempenha ou desempenhou nos últimos
                                    05 anos cargos e funções públicas listadas
                                    na regulamentação de PLDFT em vigor) e nem
                                    ser representante, familiar ou estreito
                                    colaborador de uma Pessoa Politicamente
                                    Exposta.
                                </small>
                            </div>

                            <button
                                onClick={() =>
                                    setPoliticamenteExposta(
                                        !politicamenteExposta
                                    )
                                }
                                type="button"
                                className={`politicamente-exposta ${
                                    politicamenteExposta ? "active" : ""
                                }`}
                            >
                                <img src={imgSetaBaixo} alt="" />
                                Saiba mais
                            </button>
                        </ul>
                        <motion.div
                            whileHover={{ scale: 1 }}
                            whileTap={{ scale: 0.5 }}
                            transition={{
                                duration: 1.4,
                            }}
                            className="mt-auto"
                        >
                            <Button className="slim"
                                type="submit"
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                                disabled={!props.isValid}
                            >
                                Avançar
                            </Button>

                            <button className="slim" type="button" onClick={onClick}>
                                <Text mt="28px" color="secondary">
                                    Cancelar{" "}
                                </Text>
                            </button>
                        </motion.div>
                    </Form>
                );
            }}
        </Formik>
    );
}
