import { useState, memo } from "react";
import { useHistory } from "react-router-dom";
import { Text } from "alisson-application";

import ModalAprove from "../../../../ModalAlert";
import ModalDisAprove from "../../../../ModalAlert";

import { formatDateIsoSystem } from "../../../../../../util/helper";

import imgVisualizar from "../../../../../../assets/img/visualizar.svg";
import imgDownload from "../../../../../../assets/img/dowload.svg";

import { useInfra } from "../Hook";
import { HistoryProps } from "../../../types";

import { Button } from "../../../../Button/status";

import { ReeviewStyles } from "./styles";
import { useCampanha } from "../../../../../../Hook/system/useCampanha";

function Reeview() {
    const { processes, aprove, disApproval } = useInfra();
    const { campanhaById } = useCampanha();
    const [modalAprove, setModalAprove] = useState(false);
    const [modalDisAprove, setModalDisAprove] = useState(false);
    const [id, setId] = useState<number | undefined>(undefined);
    const history = useHistory<HistoryProps>();

    async function handleAprove() {
        if (id) {
            await aprove(id);
        }
    }

    async function handleDisAprove() {
        if (id) {
            await disApproval(id);
        }
    }

    return (
        <>
            {processes.map((processe) => {
                return (
                    <ReeviewStyles key={processe.id}>
                        <ul>
                            <li className="title">
                                <Text as="p">{processe.nome}</Text>
                            </li>
                            {processe.status === "PROCESSANDO" ||
                            processe.status === "AGUARDANDO" ? (
                                <li>
                                    <Text as="p" className="processando">
                                        {processe.status}
                                    </Text>
                                </li>
                            ) : null}

                            <li>
                                <Text as="p">
                                    {formatDateIsoSystem(processe.created_at)}
                                </Text>
                            </li>
                            <li className="name">
                                <Text as="p">{processe.user.name}</Text>
                            </li>
                            <li>
                                <Text as="p">{processe.total_logs}</Text>
                            </li>
                        </ul>

                        <div className="acoes">
                            <button
                                className="background"
                                onClick={() =>
                                    history.push({
                                        pathname: `/sistema/campanha/carga/${processe.id}/log`,
                                        state: {
                                            id: processe.id,
                                            type: "users_log",
                                            carga: "infra",
                                            campanha: campanhaById.nome,
                                        },
                                    })
                                }
                            >
                                <img src={imgVisualizar} alt="" />
                            </button>
                            <button
                                className="background"
                                onClick={() => alert("Em desenvolvimento")}
                            >
                                <img src={imgDownload} alt="" />
                            </button>

                            <Button
                                color="#D50632"
                                background="#FFE3E7"
                                onClick={() => {
                                    setId(processe.id);
                                    setModalDisAprove(true);
                                }}
                            >
                                REPROVAR
                            </Button>

                            <Button
                                color="#fff"
                                background="#5DB356"
                                onClick={() => {
                                    setId(processe.id);
                                    setModalAprove(true);
                                }}
                            >
                                APROVAR
                            </Button>
                        </div>
                    </ReeviewStyles>
                );
            })}
            <ModalAprove
                handleClose={() => setModalAprove(false)}
                reject={() => setModalAprove(false)}
                aceept={async () => {
                    handleAprove();
                    setModalAprove(false);
                }}
                title="Aprovar"
                description="Deseja aprovar a carga de infra?"
                open={modalAprove}
            />

            <ModalDisAprove
                handleClose={() => {
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                reject={() => {
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                aceept={() => {
                    handleDisAprove();
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                title="Reprovar"
                description="Deseja reprovar a carga de infra?"
                open={modalDisAprove}
            />
        </>
    );
}

export const ReviewMemorized = memo(Reeview);
