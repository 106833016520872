import { Header as DefaultHeader } from "../Header";
import { HeaderStyles } from "./styles";
import { DebounceInput } from "react-debounce-input";
import Error from "../../../assets/img/error.svg";
interface IHeader {
    setSearch: (value: string) => void;
    search: string;
}

export function Header(props: IHeader) {

    const { setSearch, search} = props;

    return (
        <HeaderStyles>
            <DefaultHeader
                title="IDWALL"
                titleBtn=""
                permission="faq.cupom"
            />
            <div className="busca">
                <div className="container-input-system pesquisa">
                    <DebounceInput
                        value={search}
                        type="text"
                        debounceTimeout={1000}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        placeholder="BUSQUE POR TIPOS DE INVESTIMENTO, CONSULTA, E VALORES"
                    />
                </div>
                { search !== "" && <img src={Error} alt="Limpar" onClick={()=>setSearch("")} />}
            </div>
        </HeaderStyles >
    );
}
