import { useEffect, useState } from "react";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { useUser } from "../../Hook/user/UseUser";
import { getCampanha, getLocalStore } from "../../util/helper";

import { api } from "../../services/";

import { HomeDesktopMemo } from "../../components/Desktop/Home/";
import { Dashboard } from "../../components/Mobile/DashBoard";

import close from "../../assets/img/x-branco.svg";
import { BemVindoModal } from "../../components/Mobile/BemVindo/";
import Modal from "../../components/NovoModal";
import ModalPopUp from "../../components/NovoModal/popup";
import { Popup } from "../../components/NovoModal/styles";
import { TermoDeUso } from "../../components/TermoUso";
import { UseModal } from "../../Hook/Usemodal";
// import teste from "../../assets/img/pop_yetzpay_lancamento_mobile-20220727-153347.jpg";

interface Termo {
    title: string;
    link: string;
    labelCheck: string;
    header: string;
}

export function PageDashboard() {
    const width = window.screen.width;
    const [active, setActive] = useState(
        !getLocalStore()?.first_login
            ? getCampanha()?.force_activate_account_login
                ? false
                : true
            : false
    );
    const [hasPolitica, setHasPolitica] = useState(false);

    const { user } = useUser();
    const { cartaoId, getExtrato } = UseCartoes();
    const [modalPopup, setModalPopup] = useState(0);
    const { handleOpen } = UseModal();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [termoUso, setTermoUso] = useState<Termo>({} as Termo);

    async function popupLido(id: any, index: any) {
        try {
            const response = await api.post(`recipiente/readed`, {
                recipiente_id: id,
            });
            return response;
        } catch (error: any) {
            return error;
        } finally {
            let i = index + 1;
            if (user?.notificacoes[i]) {
                setModalPopup(user?.notificacoes[i].id);
            } else {
                setModalPopup(0);
            }
        }
    }

    useEffect(() => {
        const notification = user?.notificacoes?.find(
            (i) => i?.acao?.tipo === "POPUP"
        );

        const novoSaldo = user?.notificacoes?.find((item) =>
            item?.describe?.includes("NEW_BALANCE")
        );

        if (novoSaldo) {
            handleOpen("novoSaldo");
        }

        if (notification) {
            setModalPopup(notification.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (user?.read_recent_termos === 0 && user?.termos_uso_file) {
            setHasPolitica(true);
            setTermoUso({
                header: "Termos e Condições de Uso",
                title: "Atualizamos os Termos e Condições de Uso",
                link: user?.termos_uso_file || "",
                labelCheck: `Li, compreendi e concordo com as disposições contidas nos
                <strong>
                    <a href="https://yetz.com.br/aviso-de-privacidade/" target='_blank' rel='noopener noreferrer'>Termos e Condições de Uso</a>
                </strong> deste site. Caso não concorde, clique no botão fechar.`
            });
            return;
        }
        //
        if (user?.read_recent_politicas === 0) {
            setHasPolitica(true);
            setTermoUso({
                header: "Aviso de Privacidade",
                title: "Atualizamos nosso Aviso de Privacidade",
                link: "https://yetz.com.br/aviso-de-privacidade/",
                labelCheck: `Li, compreendi e concordo com as disposições contidas no
                <strong>
                    <a href="https://yetz.com.br/aviso-de-privacidade/" target='_blank' rel='noopener noreferrer'>Aviso de Privacidade</a>
                </strong> deste site. Caso não concorde, clique no botão fechar.`,
            });

            return;
        }

        setHasPolitica(false);
    }, [user]);

    useEffect(() => {
        if (cartaoId) {
            getExtrato(cartaoId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartaoId, getExtrato]);

    return (
        <>
            {width <= 768 ? <Dashboard /> : <HomeDesktopMemo />}

            {user?.notificacoes?.length
                ? user?.notificacoes?.map(
                      (notificaco, index) =>
                          notificaco.acao?.tipo === "POPUP" && (
                              <ModalPopUp
                                  open={
                                      modalPopup === notificaco.id
                                          ? true
                                          : false
                                  }
                                  handleClose={() =>
                                      popupLido(notificaco.id, index)
                                  }
                                  classe="popup"
                              >
                                  <Popup>
                                      <div className="container-img">
                                          <img
                                              className="close"
                                              src={close}
                                              alt=""
                                              onClick={() =>
                                                  popupLido(
                                                      notificaco.id,
                                                      index
                                                  )
                                              }
                                          />
                                          <img
                                              src={
                                                  width <= 768
                                                      ? notificaco.acao
                                                            .arte_mobile
                                                      : notificaco.acao
                                                            .arte_desktop
                                              }
                                              className="img-banner"
                                              alt=""
                                          />
                                      </div>
                                  </Popup>
                              </ModalPopUp>
                          )
                  )
                : null}

            {/* <ModalPopUp open={true} handleClose={() => {}} classe="popup">
                <Popup>
                    <div className="container-img">
                        <img
                            className="close"
                            src={close}
                            alt=""
                            onClick={() => {}}
                        />
                        <img src={teste} className="img-banner" alt="" />
                    </div>
                </Popup>
            </ModalPopUp> */}

            {active && (
                <Modal open={active} handleClose={() => {}}>
                    <BemVindoModal setActive={setActive} />
                </Modal>
            )}

            <Modal open={hasPolitica} handleClose={() => {}}>
                <TermoDeUso {...termoUso} />
            </Modal>
        </>
    );
}
