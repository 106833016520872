import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";

export interface options {
    label: string;
    value: number | string;
}

interface AutoCompleteProps {
    initalValue?: options | null;
    options: options[];
    onChange: (value: options) => void;
    tipo?:
        | "cliente"
        | "contato"
        | "user"
        | "cartao"
        | "campanha"
        | "calendario";
    label: string;
    handleBlur?: () => void;
    disabled?: boolean;
}

export default function CustomInputAutocomplete(props: AutoCompleteProps) {
    const {
        options,
        onChange,
        tipo,
        label,
        initalValue,
        handleBlur,
        disabled,
    } = props;
    const [values, setValues] = useState<options | null>(null);
    const [inputValue, setInputValue] = useState("");
    const userSide = ["user", "cartao", "campanha", "calendario"];

    useEffect(() => {
        if (initalValue) {
            setValues(initalValue);
        } else {
            setValues(null);
        }
    }, [initalValue]);

    return (
        <label>
            <Autocomplete
                sx={{
                    display: "inline-block",
                    "& input": {
                        width: "100%",
                        bgcolor: "background.paper",
                        color: (theme) =>
                            theme.palette.getContrastText(
                                theme.palette.background.paper
                            ),
                        "&:disabled": {
                            backgroundColor: "rgba(239, 239, 239, 0.3)",
                        },
                    },
                }}
                onChange={(e: object, value: any) => {
                    if (value?.value) {
                        onChange(value);
                    }
                    setValues(value);
                }}
                disabled={disabled}
                onBlur={handleBlur}
                getOptionLabel={(option) => option.label}
                id="custom-input-demo"
                options={options}
                value={values}
                inputValue={inputValue}
                onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                renderInput={(params) => (
                    <div
                        className={`${
                            userSide.includes(tipo ? tipo : "")
                                ? "container-input  cpf-cnpj"
                                : "container-input-system"
                        } ${tipo} select`}
                        ref={params.InputProps.ref}
                    >
                        <input
                            type="text"
                            {...params.inputProps}
                            placeholder={label}
                        />
                    </div>
                )}
            />
        </label>
    );
}
