import { Text, Box } from "alisson-application";
import { api } from "../../../services/";
import { getLocalStore } from "../../../util/helper";
import { Button } from "../ButtonSlim";
import { ObrigadoStyles } from "./styles";
import imgObrgigado from "../../../assets/img/obrigado.svg";
import { useUser } from "../../../Hook/user/UseUser";
import { UseModal } from "../../../Hook/Usemodal";

interface ObrigadoProps {
    onClick: () => void;
}

export function Obrigado(props: ObrigadoProps) {
    const { onClick } = props;
    const { user } = useUser();
    const { handleOpen } = UseModal();

    async function updateUser() {
        try {
            await api.post(`usuario/${getLocalStore()?.id}`, {
                first_login: 1,
            });

            const updateLocalStore = {
                ...getLocalStore(),
                first_login: 1,
            };

            localStorage.setItem("user", JSON.stringify(updateLocalStore));

            if (user.pending_document) {
                handleOpen("verificacaoDocument");
            }
        } catch (error) {
            return error;
        }
    }

    //

    return (
        <ObrigadoStyles>
            <div className="container-img">
                <img src={imgObrgigado} alt="" />
            </div>

            <div className="container-text">
                <Text as="h3">Agradecemos pelas confirmações. </Text>

                <Box mb="52px">
                    <Text as="p">Agora é só curtir sua carteira YetzPay.</Text>

                    <Text as="p">
                        Se tiver alguma dúvida você pode consultar os tutoriais
                        ou entrar em contato com a gente pelo Fale Conosco.
                    </Text>
                </Box>
            </div>

            <Button
                onClick={async () => {
                    onClick();
                    await updateUser();
                }}
            >
                Fechar
            </Button>
        </ObrigadoStyles>
    );
}
