import styled from "styled-components";

export const Container = styled.div`

    .limit {
        display: flex;
        align-items: center;
        
        p {
            flex:1;
            display: flex;
            align-items: center;
            max-width:380px;
            width:100%;
            height: 40px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${({ theme }) => theme.colors.primary};
            font-family: "Oxanium", Arial, Helvetica, sans-serif;
        }

        margin-bottom: 35px;
    }
`;
