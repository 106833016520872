import { Text } from "alisson-application";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import imgDownload from "../../../../../../assets/img/dowload.svg";
import imgVisualizar from "../../../../../../assets/img/visualizar.svg";
import { useCampanha } from "../../../../../../Hook/system/useCampanha";
import {
    formatDateIsoSystem,
    getLocalStore,
} from "../../../../../../util/helper";
import { Button } from "../../../../Button/status";
import {
    default as ModalAprove,
    default as ModalDisAprove,
} from "../../../../ModalAlert";
import { Permissions } from "../../../../Permission";
import { Pagination } from "../../../../Table";
import { HistoryProps } from "../../../types";
import { ProcessData, useCredito } from "../Hook";

import { PaginationSection, ReeviewStyles } from "./styles";

export function Reeview() {
    const { inProcess, aprove, disApproval } = useCredito();
    const { campanhaById } = useCampanha();
    const history = useHistory<HistoryProps>();

    const [modalAprove, setModalAprove] = useState(false);
    const [modalDisAprove, setModalDisAprove] = useState(false);
    const [id, setId] = useState<number | undefined>(undefined);
    const [processes, setProcesses] = useState<ProcessData>({} as ProcessData);

    const permission = getLocalStore().permissions["carga.aprovar_valor"];

    const fetchData = useCallback(
        async (page?: number) => {
            const reponse = await inProcess(page);
            setProcesses(reponse);
        },
        [inProcess]
    );

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    async function handleAprove() {
        if (id) {
            const response = await aprove(id);
            if (response.status === 200) {
                fetchData();
            }
        }
    }

    async function handleDisAprove() {
        if (id) {
            const response = await disApproval(id);
            if (response.status === 200) {
                fetchData();
            }
        }
    }
  
    return (
        <>
            {processes.data &&
                processes?.data.map((processe) => {
                    return (
                        <ReeviewStyles key={processe.id}>
                            <ul>
                                <li className="title" title={processe.nome}>
                                    <Text as="p">{processe.nome}</Text>
                                </li>
                                {processe.status === "PROCESSANDO" ||
                                processe.status === "AGUARDANDO" ? (
                                    <li>
                                        <Text as="p" className="processando">
                                            {processe.status}
                                        </Text>
                                    </li>
                                ) : null}
                                <li>
                                    <Text as="p">
                                        {formatDateIsoSystem(
                                            processe.created_at
                                        )}
                                    </Text>
                                </li>
                                <li className="name">
                                    <Text as="p">{processe.user.name}</Text>
                                </li>
                                <li>
                                    <Text as="p">{processe.total_logs}</Text>
                                </li>

                                {processe?.total_itens_credito_sum ? (
                                    <li>
                                        <Text as="p">
                                            R${" "}
                                            {processe.total_itens_credito_sum}
                                        </Text>
                                    </li>
                                ) : null}
                            </ul>

                            <div
                                className={`acoes ${
                                    permission ? "" : "not-permission"
                                }`}
                            >
                                <button
                                    className="background"
                                    onClick={() =>
                                        history.push({
                                            pathname: `/sistema/campanha/carga/${processe.id}/log`,
                                            state: {
                                                id: processe.id,
                                                type: "creditos_log",
                                                carga: "users",
                                                campanha: campanhaById.nome,
                                            },
                                        })
                                    }
                                >
                                    <img src={imgVisualizar} alt="" />
                                </button>

                                <button
                                    className="background"
                                    onClick={() => alert("Em desenvolvimento")}
                                >
                                    <img src={imgDownload} alt="" />
                                </button>
                                <Permissions role="carga.aprovar_valor">
                                    <Button
                                        color="#D50632"
                                        background="#FFE3E7"
                                        onClick={() => {
                                            setId(processe.id);
                                            setModalDisAprove(true);
                                        }}
                                    >
                                        REPROVAR
                                    </Button>

                                    <Button
                                        color="#fff"
                                        background="#5DB356"
                                        onClick={() => {
                                            setId(processe.id);
                                            setModalAprove(true);
                                        }}
                                    >
                                        APROVAR
                                    </Button>
                                </Permissions>
                            </div>
                        </ReeviewStyles>
                    );
                })}

            {processes?.last_page > 1 ? (
                <PaginationSection>
                    <Pagination
                        total={processes.last_page}
                        currentPage={(page) => fetchData(page)}
                    />
                </PaginationSection>
            ) : null}

            <ModalAprove
                handleClose={() => setModalAprove(false)}
                reject={() => setModalAprove(false)}
                aceept={() => {
                    handleAprove();
                    setModalAprove(false);
                }}
                title="Aprovar"
                description="Deseja aprovar a carga de crédito?"
                open={modalAprove}
            />

            <ModalDisAprove
                handleClose={() => {
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                reject={() => {
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                aceept={() => {
                    handleDisAprove();
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                title="Reprovar"
                description="Deseja reprovar a carga de crédito?"
                open={modalDisAprove}
            />
        </>
    );
}
