import { LimitiCardsStyles } from "./styles";
import imgCardFisico from "../../../assets/img/card-fisico.svg";
import imgCardVirtual from "../../../assets/img/card-virtual.svg";
import {
    tempoFimPlataforma,
    tempoFimPlataformaExtendido,
    tempoInicioPlataforma,
    tempoInicioPlataformaExtendido,
} from "../../../util/helper";

type LimitiCardsProps = {
    type?: "boleto" | "cartToCard";
};

export function LimitiCards({type}:LimitiCardsProps) {
    return (
        <LimitiCardsStyles>
            <div className="container-alerta">
                <h3>Atenção!</h3>
                <p>
                {type === "boleto"  
                    ? <strong>Pagamento de Contas com:</strong>
                    :<strong>Transferência da Carteira para:</strong>
                }
                </p>
            </div>

            <div className="contaner-cartao-fisico">
                <div className="container-img">
                    <img src={imgCardFisico} alt="" />
                </div>

                <div className="container-text">
                    <h4>Cartões Físicos:</h4>
                        {type === "boleto"  
                            ? <p>Boleto de até R$ 1.500,00 (máximo de R$ 10.000,00 por mês, em dias úteis, das {tempoInicioPlataforma}h às {tempoFimPlataforma}h).</p>
                            : <p>Até R$ 10.000,00 mensais, <br/>em dias úteis, das {tempoInicioPlataformaExtendido}h às {tempoFimPlataformaExtendido}h.</p>
                        }
                </div>
            </div>

            <div className="contaner-cartao-virtual">
                <div className="container-img">
                    <img src={imgCardVirtual} alt="" />
                </div>

                <div className="container-text">
                    <h4>Cartões Virtuais:</h4>
                    {type === "boleto"  
                        ? <p>Boleto de até R$ 1.500,00 (máximo de R$ 1.500,00 por mês, em dias úteis, das {tempoInicioPlataforma}h às {tempoFimPlataforma}h).</p>
                        : <>
                            <p>Até R$ 1.500,00 mensais, <br/>em dias úteis, das {tempoInicioPlataformaExtendido}h às {tempoFimPlataformaExtendido}h.</p>
                            <p>(Para transferir valores mais altos, solicite Cartão Físico).</p>
                        </>
                    }
                </div>
            </div>
        </LimitiCardsStyles>
    );
}
